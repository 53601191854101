import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import SiteRetentionItems from 'components/common/SiteRetentionItems';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import {
  formatDate,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Modal, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';

interface Project {
  id: number;
  name: string;
  wo_id: string;
  end_date: string;
  start_date: string;
  wo_amount: string;
  no_of_sites: string;
  status: number;
  date: any;
}

interface Site {
  id: number;
  site_name: string;
  workorder: {
    conpany: {
      name: string;
    };
  };
}
interface Company {
  id: number;
  name: string;
  industry: {
    name: string;
  };
}

interface Field {
  date: string;
  amount: string;
  status: string;
}

const SiteRetentionListView = () => {
  const navigate = useNavigate();
  const [retentionList, setRetentionList] = useState([]);
  const [siteList, setSiteList] = useState<Site[]>([]);
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [siteId, setSiteId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [retentionId, setRetentionId] = useState('');
  const [retentionStatusId, setRetentionStatusId] = useState('');
  const [show, setShow] = useState(false);
  const [fields, setFields] = useState<Field>({
    date: '',
    amount: '',
    status: ''
  });

  console.log('companyList', companyList);

  const [retentionStatus, setRetentionStatus] = useState(''); // State to store retention status
  const [role, setRole] = useState(0);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  // Function to handle select change
  const handleRetentionStatusChange = (event: any) => {
    setRetentionStatus(event.target.value); // Update state with selected value
  };

  const workOrderListTableColumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'siteretention.site.site_name',
      header: 'Site Name',
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '15%' } }
      }
    },
    {
      accessorKey: 'siteretention.site.id',
      header: 'Site ID',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'siteretention.site.wo_id',
      header: 'WO ID',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },

    {
      header: 'Client Name',
      accessorKey: 'siteretention.site.workorder.company.name',
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-3' }
      }
    },
    {
      header: 'Retention date',
      accessorKey: 'date',
      cell: ({ row: { original } }) => {
        return <>{formatDate(original.date)}</>;
      },
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '15%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Retention Status',
      cell: ({ row: { original } }) => {
        const { status } = original;
        return (
          <p className="fw-bold text-body-emphasis">
            {status === 1
              ? 'Claimed'
              : status === 2
              ? 'Received'
              : status === 0
              ? 'Pending'
              : ''}
          </p>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1 || role === 3) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <SiteRetentionItems editAction={() => handleShow(original)} />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: retentionList,
    columns: workOrderListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  useEffect(() => {
    fatch();
    fatchSiteList();
    fatchCompanyList();
  }, []);

  useEffect(() => {
    setFields(prevFields => ({
      ...prevFields,
      status: retentionStatus
    }));
  }, [retentionStatus]);

  const handleClose = () => {
    setRetentionStatus('');
    setShow(false);
  };

  const handleShow = (data: any) => {
    setRetentionId(data.id);
    setShow(true);
    setFields({
      date: data.date,
      amount: data.amount,
      status: retentionStatus
    });
  };

  const handleSave = async () => {
    const data = jsonToFormData(fields);

    let response: any = {};
    if (retentionId) {
      response = await addService(
        `retention_detail_update/${retentionId}`,
        data
      );
    }
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fatch();
      handleClose();
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
    // }
  };

  const fatch = async () => {
    // const response = await getService('retention');
    const response = await getService(`retention_filter/${0}/${0}/${0}`);
    if (response.status === 200) {
      setRetentionList(response.data);
    }
  };
  const fatchSiteList = async () => {
    const response = await getService('site_completed');
    if (response.status === 200) {
      setSiteList(response.data);
    }
  };
  const fatchCompanyList = async () => {
    const response = await getService('company');
    if (response.status === 200) {
      setCompanyList(response.data);
    }
  };

  const handleSiteChange = async (selectSiteId: string) => {
    if (selectSiteId) {
      setSiteId(selectSiteId);
      const response = await getService(
        `retention_filter/${selectSiteId}/${companyId ? companyId : 0}/${
          retentionId ? retentionId : 0
        }`
      );
      if (response.status === 200) {
        setRetentionList(response.data);
      }
    } else {
      const response = await getService(
        `retention_filter/${siteId ? siteId : 0}/${companyId ? companyId : 0}/${
          retentionId ? retentionId : 0
        }`
      );
      if (response.status === 200) {
        setRetentionList(response.data);
      }
    }
  };

  const handleCompanyChange = async (selectedStatus: string) => {
    if (selectedStatus) {
      setCompanyId(selectedStatus);
      const response = await getService(
        `retention_filter/${siteId ? siteId : 0}/${selectedStatus}/${
          retentionId ? retentionId : 0
        }`
      );
      if (response.status === 200) {
        setRetentionList(response.data);
      }
    } else {
      const response = await getService(
        `retention_filter/${siteId ? siteId : 0}/${companyId ? companyId : 0}/${
          retentionId ? retentionId : 0
        }`
      );
      if (response.status === 200) {
        setRetentionList(response.data);
      }
    }
  };

  const handleRetentionChange = async (selectedMaterial: string) => {
    if (selectedMaterial) {
      setRetentionId(selectedMaterial);
      const response = await getService(
        `retention_filter/${siteId ? siteId : 0}/${
          companyId ? companyId : 0
        }/${selectedMaterial}`
      );
      if (response.status === 200) {
        setRetentionList(response.data);
      }
    } else {
      const response = await getService(
        `retention_filter/${siteId ? siteId : 0}/${companyId ? companyId : 0}/${
          retentionId ? retentionId : 0
        }`
      );
      if (response.status === 200) {
        setRetentionList(response.data);
      }
    }
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h3 className="mb-0">
            <span className="me-3">Site Retention</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({retentionList.length})
            </span>
          </h3>
          {/* <Link className="btn btn-primary px-5" to="/createSiteRetention">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Create Site Retention
          </Link> */}
          <Col xs="auto">
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Select
                value={retentionId}
                onChange={e => handleRetentionChange(e.target.value)}
              >
                <option value="0">Select Retention</option>
                <option value="1">Today</option>
                <option value="7">1 Week </option>
                <option value="14">2 Week </option>
                <option value="21">3 Week </option>
                <option value="30">1 Month </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Select
                value={companyId}
                onChange={e => handleCompanyChange(e.target.value)}
              >
                <option value="0">Select Client</option>
                {companyList.length > 0 &&
                  companyList.map((item: Company) => (
                    <option key={item.id} value={item.id}>
                      {`${item.name}- ${item.industry.name} `}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs="auto">
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Select
                value={siteId}
                onChange={e => handleSiteChange(e.target.value)}
              >
                <option value="0">Select Site</option>
                {siteList.length > 0 &&
                  siteList.map((item: Site) => (
                    <option key={item.id} value={item.id}>
                      {item.site_name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </div>
        <ProjectsTopSection activeView="list" />
        <SupplireListTable />
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Site Retention Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} xl={12}>
                <Row as="form" className="g-3 mb-6">
                  <Col sm={6} md={6}>
                    <FloatingLabel
                      controlId="floatingSelectPrivacy"
                      label="Retention Status"
                    >
                      <Form.Select
                        name="retentionStatus"
                        value={retentionStatus} // Bind value from state
                        onChange={handleRetentionStatusChange} // Handle change event
                      >
                        <option>Select Status</option>
                        <option value="1">Claimed</option>
                        <option value="2">Received</option>
                        <option value="0">Pending</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </AdvanceTableProvider>
    </div>
  );
};

export const SupplireListTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default SiteRetentionListView;

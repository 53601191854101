import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import SiteCardViewItem from 'components/modules/project-management/card-view/SiteCardViewItem';
import { Site } from 'data/project-management/projects';
import Loader from 'helpers/Loader';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getService } from 'services/services';

export const columns: ColumnDef<Site>[] = [
  {
    // For filtering and searching projects by status
    id: 'site_id',
    accessorFn: ({ site_id }) => site_id
  },
  {
    // For searching projects by name
    accessorKey: 'name'
  }
];

const SiteListCardView = () => {
  const [siteList, setSiteList] = useState([]);
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('site');
    if (response.status === 200) {
      setLoading(false);
      setSiteList(response.data);
    } else if (response.status === 500) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  const table = useAdvanceTable<Site>({
    data: siteList,
    columns,
    pageSize: 1000,
    pagination: true,
    sortable: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Sites</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({siteList.length})
            </span>
          </h2>

          {role === 1 && (
            <Link className="btn btn-primary px-5" to="/site/create-new">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Create New Site
            </Link>
          )}
        </div>
        {/* <ProjectsTopSection activeView="card" /> */}
        {/* <div className="d-flex mb-3">
          <Link to="#" className="me-2 tex">
            All({siteList.length})
          </Link>
          <>
            <Link to="#" className="tex">
              3 Day to go
            </Link>
            <p className="me-2 tex" style={{ color: 'orange' }}>
              (0)
            </p>
          </>{' '}
          <>
            <Link to="#" className="tex">
              5 Day to go
            </Link>
            <p className="me-2 tex" style={{ color: 'yellow' }}>
              (0)
            </p>
          </>
          <>
            <Link to="#" className="tex">
              Same
            </Link>
            <p className="me-2 tex" style={{ color: 'red' }}>
              (0)
            </p>
          </>
          <>
            <Link to="#" className="tex">
              Overdue
            </Link>
            <p className="me-2 tex" style={{ color: 'red' }}>
              (0)
            </p>
          </>
        </div> */}
        <Row className="g-3 mb-9">
          {table
            .getRowModel()
            .rows.map(row => row.original)
            .map(site => (
              <Col xs={12} sm={6} xl={4} xxl={3} key={site.id}>
                <SiteCardViewItem project={site} />
              </Col>
            ))}
        </Row>
      </AdvanceTableProvider>
    </div>
  );
};

export default SiteListCardView;

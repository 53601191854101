import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import { deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { dateFormat, validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
import JsonToCsvButton from 'components/JsonToCsvButton';
import Loader from 'helpers/Loader';

interface Inventory {
  total_amt: any;
  gst_amt: any;
  material: {
    name: string;
    current_stock: number;
  };
  id: number;
  batch_no: number;
  barcode: number;
  purchase_rate: number;
  maintan_date: string;
}

const InventoryViewList = () => {
  const navigate = useNavigate();
  const [inventoryList, setInventoryList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState('');
  const [materialId, setMaterialId] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchMaterial = async () => {
    const response = await getService('material');
    if (response.status === 200) {
      setMaterialList(response.data);
    }
  };

  const fetchState = async () => {
    const response = await getService('gstlist');
    if (response.status === 200) {
      setStateList(response.data);
    }
  };

  const handleStateChange = async (selectedStateId: string) => {
    if (selectedStateId) {
      setStateId(selectedStateId);
      const response = await getService(
        `master_inventory/${materialId ? materialId : 0}/${selectedStateId}`
      );
      if (response.status === 200) {
        setInventoryList(response.data);
      } else {
        setInventoryList(response.data);
      }
    } else {
      `master_inventory/${materialId ? materialId : 0}/${
        stateId ? stateId : 0
      }`;
    }
  };

  const handleMaterialChange = async (selectedMaterialId: string) => {
    if (selectedMaterialId) {
      setMaterialId(selectedMaterialId);
      const response = await getService(
        `master_inventory/${selectedMaterialId}/${stateId ? stateId : 0}`
      );
      if (response.status === 200) {
        setInventoryList(response.data);
      } else {
        setInventoryList(response.data);
      }
    } else {
      `master_inventory/${materialId ? materialId : 0}/${
        stateId ? stateId : 0
      }`;
    }
  };

  const filteredData = inventoryList.map((data: any) => ({
    invoice_no: data.invoice_no,
    barcode: data.barcode,
    batch_no: data.batch_no,
    material: data.material.name,
    unit_title: data.material.unitdata.unit_title,
    current_stock: data.material.current_stock,
    purchase_rate: data.purchase_rate,
    gst_amt: data.gst_amt,
    total_amt: data.total_amt
  }));

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Inventory>[] = [
    {
      accessorKey: 'name',
      header: 'Material Name',
      cell: ({ row: { original } }) => {
        const { name } = original.material || {}; // Handle potential null or undefined
        return (
          <Link to="#!" className="d-flex align-items-center text-body">
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{name}</p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' }, // Adjust width and minWidth as needed
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'batch_no',
      header: 'Batch No.',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'barcode',
      header: 'Barcode No.',
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'current_stock',
      header: 'Current Stock',
      cell: ({ row: { original } }) => {
        // Check if original.material exists and has current_stock property
        if (
          !original.material ||
          original.material.current_stock === undefined
        ) {
          return null;
        }

        const { current_stock } = original.material;

        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {current_stock}
          </p>
        );
      }
    },
    {
      accessorKey: 'purchase_rate',
      header: 'Purchase Rate',
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'maintan_date',
      header: 'Expiry Date',
      cell: ({ row: { original } }) => {
        const { maintan_date } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {dateFormat(maintan_date)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'lastActive',
      header: 'Amount without GST',
      cell: ({ row: { original } }) => {
        const { total_amt, gst_amt } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {total_amt - gst_amt}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'masterinvoice.sbu.state.name',
      header: 'STATE GST',
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    }
    // {
    //   id: 'action',
    //   cell: ({ row: { original } }) => {
    //     const { id } = original;
    //     return (
    //       <RevealDropdownTrigger>
    //         <RevealDropdown>
    //           <ActionDropdownItems
    //           // editAction={() => navigate(`/contractors/create-new/${id}`)}
    //           // removeAction={() => confirmAlert(id)}
    //           />
    //         </RevealDropdown>
    //       </RevealDropdownTrigger>
    //     );
    //   },
    //   meta: {
    //     headerProps: { style: { width: '10%' }, className: 'text-end' },
    //     cellProps: { className: 'text-end' }
    //   }
    // }
  ];

  const table = useAdvanceTable({
    data: inventoryList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
    fetchMaterial();
    fetchState();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService(`master_inventory/${0}/${0}`);
    if (response.status === 200) {
      setLoading(false);
      setInventoryList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Inventory</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="Material">
                  <Form.Select
                    name="Material Filter"
                    onChange={e => handleMaterialChange(e.target.value)}
                  >
                    <option value="0">Select Material</option>
                    {materialList.length > 0 &&
                      materialList.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="State">
                  <Form.Select
                    name="Material Filter"
                    onChange={e => handleStateChange(e.target.value)}
                  >
                    <option value="0">Select State</option>
                    {stateList.length > 0 &&
                      stateList.map((item: any) => (
                        <option key={item.id} value={item.state_id}>
                          {item.state.name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                {/* <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button> */}
                <JsonToCsvButton
                  data={filteredData}
                  filename="Inventory Report.csv"
                />
                <Link className="btn btn-primary px-5" to="/CreateInventory">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Inventory
                </Link>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default InventoryViewList;

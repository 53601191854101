import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import IndustryListTable from 'components/tables/IndustryListTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Button from 'components/base/Button';
import { addService, deleteService, getService } from 'services/services';
import {
  formatDate,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { ColumnDef } from '@tanstack/react-table';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { Link } from 'react-router-dom';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { toast } from 'react-toastify';
import Loader from 'helpers/Loader';

interface Project {
  id: number;
  name: string;
  created_at: string;
}
type EditAction = () => void;

const ExpenseListView = () => {
  const [expenseList, setExpenseList] = useState([]);
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState('');
  const [expenseId, setExpenseId] = useState('');
  const [expenseName, setExpenseName] = useState('');
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleShow = (data: any) => {
    setShow(true);
    setExpenseId(data.id);
    setExpenseName(data.expense_title);
  };
  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setExpenseId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`expenseMaster/${expenseId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setExpenseId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const IndustryListTableColumns: ColumnDef<Project>[] = [
    {
      header: 'Id',
      accessorKey: 'id',
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'expense_title',
      header: 'Name',
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '30%' } }
      }
    },
    {
      header: 'CREATE DATE',
      accessorKey: 'created_at',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return <>{formatDate(created_at)}</>;
      },
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-3' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <ActionDropdownItems
                  editAction={() => handleShow(original)}
                  removeAction={() => confirmAlert(id)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: expenseList,
    columns: IndustryListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  const handleClose = () => {
    setShow(false);
    setExpenseName('');
    setError('');
  };

  const handleSave = async () => {
    setLoading(true);
    if (expenseName === '') {
      setLoading(false);
      setError('Please enter an expense title.');
      return;
    } else {
      const data = jsonToFormData({ name: expenseName });
      let response: any = {};
      if (expenseId) {
        response = await addService(`expenseMaster/${expenseId}`, data);
      } else {
        response = await addService('expenseMaster', data);
      }
      if (response?.status === 200) {
        setLoading(false);
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        fetch();
        handleClose();
      } else if (response?.status === 500) {
        setLoading(false);
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    }
  };

  const fetch = async () => {
    setLoading(true);
    const response = await getService('expenseMaster');
    if (response.status === 200) {
      setLoading(false);
      setExpenseList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  if (loading) return <Loader />;

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Expense'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Expense List</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({expenseList.length})
            </span>
          </h2>
          {role === 1 && (
            <Button
              onClick={() => setShow(true)}
              variant="primary"
              className="px-5"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Create Expense
            </Button>
          )}
        </div>
        <ProjectsTopSection activeView="list" />
        <IndustryListTable />
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{expenseId ? 'Edit' : 'Create'} Expense</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Add Expense</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Expense title"
                  autoFocus
                  value={expenseName}
                  onChange={e => {
                    setExpenseName(e.target.value);
                    setError('');
                  }}
                  isInvalid={error !== ''}
                />
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </AdvanceTableProvider>
    </div>
  );
};

export default ExpenseListView;

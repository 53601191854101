// import React, { useEffect, useState } from 'react';
// import { Container, Col, Row } from 'react-bootstrap';
// import SiteMateReqViewTable from './SiteMateReqViewTable';
// import SiteMachinaryReport from './SiteMachinaryReport';
// import EcomProjectionVsActual from 'components/modules/e-commerce/dashboard/EcomProjectionVsActual';
// import { getService } from 'services/services';
// import ReactECharts from 'echarts-for-react';
// import * as echarts from 'echarts/core';
// import { TooltipComponent, LegendComponent } from 'echarts/components';
// import { PieChart } from 'echarts/charts';
// import { CanvasRenderer } from 'echarts/renderers';
// import { EChartsOption } from 'echarts';

// // Register ECharts components
// echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer]);

// const SitesReportViewTable = () => {
//   const [activeSite, setActiveSite] = useState<number>(0);
//   const [compSite, setCompSite] = useState<number>(0);
//   const [retention, setRetention] = useState<number>(0);
//   const [claim, setClaim] = useState<number>(0);
//   const [received, setReceived] = useState<number>(0);

//   const TotalSite = compSite + activeSite;

//   useEffect(() => {
//     SiteActive();
//     SiteComplete();
//     SiteRetention();
//   }, []);

//   const SiteComplete = async () => {
//     const response = await getService('site');
//     if (response.status === 200) {
//       setActiveSite(response.data.length);
//     }
//   };

//   const SiteActive = async () => {
//     const response = await getService('site_completed');
//     if (response.status === 200) {
//       setCompSite(response.data.length);
//     }
//   };

//   const SiteRetention = async () => {
//     const response = await getService('retention');
//     if (response.status === 200) {
//       const claimed = response.data.filter(
//         (item: any) => item.status === 1
//       ).length;
//       setClaim(claimed);
//       const receive = response.data.filter(
//         (item: any) => item.status === 2
//       ).length;
//       setReceived(receive);
//       setRetention(response.data.length);
//     }
//   };

//   const option: EChartsOption = {
//     color: ['green', 'yellow'],
//     tooltip: { trigger: 'item' },
//     legend: { top: '5%', left: 'center' },
//     series: [
//       {
//         name: 'Site Count',
//         type: 'pie',
//         radius: ['40%', '70%'],
//         avoidLabelOverlap: false,
//         label: { show: false },
//         emphasis: {
//           label: { show: true, fontSize: 20, fontWeight: 'bold' }
//         },
//         data: [
//           { value: compSite, name: 'Complete Sites' },
//           { value: activeSite, name: 'Active Sites' }
//         ]
//       }
//     ]
//   };

//   const options: EChartsOption = {
//     tooltip: { trigger: 'item' },
//     legend: { top: '5%', left: 'center' },
//     series: [
//       {
//         name: 'Staff',
//         type: 'pie',
//         radius: ['40%', '70%'],
//         avoidLabelOverlap: false,
//         label: { show: false },
//         emphasis: {
//           label: { show: true, fontSize: 20, fontWeight: 'bold' }
//         },
//         data: [
//           { value: claim, name: 'Active' },
//           { value: received, name: 'Inactive' }
//         ]
//       }
//     ]
//   };

//   return (
//     <Container fluid>
//       <Row>
//         <Col xs={12} className="mt-3">
//           <h5 className="text-center">Overdue Sites</h5>
//           <div className="border rounded p-4">
//             <EcomProjectionVsActual />
//           </div>
//         </Col>
//       </Row>
//       <Row>
//         <Col xs={12} md={4} className="mt-3">
//           <div className="border rounded" style={{ height: '400px' }}>
//             <h4 className="text-center my-3">Site Progress</h4>
//             <ReactECharts option={option} style={{ height: '300px' }} />
//             <div className="row px-1">
//               <div className="col text-center">
//                 <h6>Total: {TotalSite}</h6>
//               </div>
//               <div className="col text-center">
//                 <h6>Complete: {compSite}</h6>
//               </div>
//               <div className="col text-center">
//                 <h6>Active: {activeSite}</h6>
//               </div>
//             </div>
//           </div>
//         </Col>
//         <Col xs={12} md={8} className="mt-3">
//           <div
//             className="px-4 py-3 border rounded"
//             style={{ height: '400px', overflow: 'auto' }}
//           >
//             <SiteMateReqViewTable />
//           </div>
//         </Col>
//       </Row>
//       <Row>
//         <Col xs={12} md={8} className="mt-3">
//           <div
//             className="px-4 py-3 border rounded"
//             style={{ height: '400px', overflow: 'auto' }}
//           >
//             <SiteMachinaryReport />
//           </div>
//         </Col>
//         <Col xs={12} md={4} className="py-4">
//           <div className="border rounded" style={{ height: '400px' }}>
//             <h3 className="text-center text-uppercase mt-2">Staff</h3>
//             <ReactECharts option={options} style={{ height: '300px' }} />
//             <div className="row">
//               <div className="col text-center">
//                 <p>Active: {claim}</p>
//               </div>
//               <div className="col text-center">
//                 <p>Inactive: {received}</p>
//               </div>
//             </div>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default SitesReportViewTable;

import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import SiteMateReqViewTable from './SiteMateReqViewTable';
import SiteMachinaryReport from './SiteMachinaryReport';
import EcomProjectionVsActual from 'components/modules/e-commerce/dashboard/EcomProjectionVsActual';
import { getService } from 'services/services';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { EChartsOption } from 'echarts';

// Register ECharts components
echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer]);

const SitesReportViewTable = () => {
  const [activeSite, setActiveSite] = useState<number>(0);
  const [compSite, setCompSite] = useState<number>(0);
  const [retention, setRetention] = useState<number>(0);
  const [claim, setClaim] = useState<number>(0);
  const [received, setReceived] = useState<number>(0);

  const TotalSite = compSite + activeSite;

  useEffect(() => {
    SiteActive();
    SiteComplete();
    SiteRetention();
  }, []);

  const SiteComplete = async () => {
    const response = await getService('site');
    if (response.status === 200) {
      setActiveSite(response.data.length);
    }
  };

  const SiteActive = async () => {
    const response = await getService('site_completed');
    if (response.status === 200) {
      setCompSite(response.data.length);
    }
  };

  const SiteRetention = async () => {
    const response = await getService('staff');
    if (response.status === 200) {
      const claimed = response.data.filter(
        (item: any) => item.status === 1
      ).length;
      setClaim(claimed);
      const receive = response.data.filter(
        (item: any) => item.status === 2
      ).length;
      setReceived(receive);
      setRetention(response.data.length);
    }
  };

  const option: EChartsOption = {
    color: ['green', 'yellow'],
    tooltip: { trigger: 'item' },
    legend: { top: '5%', left: 'center' },
    series: [
      {
        name: 'Site Count',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: { show: false },
        emphasis: {
          label: { show: true, fontSize: 20, fontWeight: 'bold' }
        },
        data: [
          { value: compSite, name: 'Complete Sites' },
          { value: activeSite, name: 'Active Sites' }
        ]
      }
    ]
  };

  const options: EChartsOption = {
    tooltip: { trigger: 'item' },
    legend: { top: '5%', left: 'center' },
    series: [
      {
        name: 'Staff',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: { show: false },
        emphasis: {
          label: { show: true, fontSize: 20, fontWeight: 'bold' }
        },
        data: [
          { value: claim, name: 'Active' },
          { value: received, name: 'Inactive' }
        ]
      }
    ]
  };

  return (
    <Container fluid>
      {/* <Row>
        <Col xs={12} className="mt-3">
          <h5 className="text-center">Overdue Sites</h5>
          <div className="border rounded p-4">
            <EcomProjectionVsActual />
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col xs={12} md={6} className="mt-3">
          <div className="border rounded" style={{ height: '400px' }}>
            <h4 className="text-center my-3">Site Progress</h4>
            <ReactECharts option={option} style={{ height: '300px' }} />
            <div className="row px-1">
              <div className="col text-center">
                <h6>Total: {TotalSite}</h6>
              </div>
              <div className="col text-center">
                <h6>Complete: {compSite}</h6>
              </div>
              <div className="col text-center">
                <h6>Active: {activeSite}</h6>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-3">
          <div className="border rounded" style={{ height: '400px' }}>
            <h3 className="text-center text-uppercase mt-2">Staff</h3>
            <ReactECharts option={options} style={{ height: '300px' }} />
            <div className="row">
              <div className="col text-center">
                <p>Active: {claim}</p>
              </div>
              <div className="col text-center">
                <p>Inactive: {received}</p>
              </div>
            </div>
          </div>
        </Col>
        {/* <Col xs={12} md={8} className="mt-3">
          <div
            className="px-4 py-3 border rounded"
            style={{ height: '400px', overflow: 'auto' }}
          >
            <SiteMateReqViewTable />
          </div>
        </Col> */}
      </Row>
      <Row>
        {/* <Col xs={12} md={8} className="mt-3">
          <div
            className="px-4 py-3 border rounded"
            style={{ height: '400px', overflow: 'auto' }}
          >
            <SiteMachinaryReport />
          </div>
        </Col> */}
        {/* <Col xs={12} md={4} className="py-4">
          <div className="border rounded" style={{ height: '400px' }}>
            <h3 className="text-center text-uppercase mt-2">Staff</h3>
            <ReactECharts option={options} style={{ height: '300px' }} />
            <div className="row">
              <div className="col text-center">
                <p>Active: {claim}</p>
              </div>
              <div className="col text-center">
                <p>Inactive: {received}</p>
              </div>
            </div>
          </div>
        </Col> */}
      </Row>
    </Container>
  );
};

export default SitesReportViewTable;

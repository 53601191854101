import { faPaperclip, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import Loader from 'helpers/Loader';
import {
  formatDateForAPI,
  imageURL,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';

interface Common {
  id: number;
  name: string;
}

interface WorkOrder {
  id: number;
  name: string;
}
interface Contractor {
  id: number;
  first_name: string;
  last_name: string;
}

interface Field {
  first_name: string;
  last_name: string;
  email: string;
  mobile_no: string;
  aadhar_no: string;
  country: string;
  state: string;
  city: string;
  address: string;
  bank_name: string;
  ifsc_code: string;
  account_no: string;
  id_card_type: string;
  status: string;
  joining_date: string | Date;
  paid_leave: string;
  medical_leave: string;
  working_hours: string;
  overtime_rate: string;
  monthly_salary: string;
}

const CreateStaff = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([]);
  const [contractorList, setContractorList] = useState<Contractor[]>([]);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState<Field>({
    first_name: '',
    last_name: '',
    email: '',
    mobile_no: '',
    aadhar_no: '',
    country: '101',
    state: '',
    city: '',
    address: '',
    bank_name: '',
    ifsc_code: '',
    account_no: '',
    id_card_type: '',
    status: '',
    joining_date: '',
    paid_leave: '',
    medical_leave: '',
    working_hours: '',
    overtime_rate: '',
    monthly_salary: ''
  });

  console.log('fields', fields);

  const [image, setImage] = useState<any>(null);
  const [imagePre, setImagePre] = useState<File | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(file);
      setImagePre(null);
    }
  };

  const clearImage = (event: any) => {
    event.preventDefault();
    setImagePre(null);
    setImage(null);
  };

  useEffect(() => {
    if (id) {
      fetchCompleteDetails(id);
    }
  }, [id]);

  const fetchCompleteDetails = async (id: any) => {
    const response = await getService(`staff/${id}`);
    if (response.status === 200) {
      setImagePre(response.data.profile_image);
      setFields(prevFields => ({
        ...prevFields,
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        email: response.data.email,
        mobile_no: response.data.mobile_no,
        aadhar_no: response.data.aadhar_no,
        country: response.data.country,
        state: response.data.state,
        city: response.data.city,
        address: response.data.address,
        bank_name: response.data.bank_name,
        ifsc_code: response.data.ifsc_code,
        account_no: response.data.account_no,
        id_card_type: response.data.id_card_type,
        status: response.data.status,
        joining_date: response.data.joining_date,
        paid_leave: response.data.paid_leave,
        medical_leave: response.data.medical_leave,
        working_hours: response.data.working_hours,
        overtime_rate: response.data.overtime_rate,
        monthly_salary: response.data.monthly_salary
      }));
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));

    if (name === 'state') {
      getCity(value);
    }
  };

  useEffect(() => {
    getState();
    getWorkOrder();
    getContractor();
  }, []);

  useEffect(() => {
    if (fields.state) {
      getCity(fields.state);
    }
  }, [fields.state]);

  const getWorkOrder = async () => {
    const response = await getService('workorder');
    if (response.status === 200) {
      setWorkOrderList(response.data);
    }
  };
  const getContractor = async () => {
    const response = await getService('contractor');
    if (response.status === 200) {
      setContractorList(response.data);
    }
  };
  const getState = async () => {
    const response = await getService(`state-by-country/${101}`);
    if (response.status === 200) {
      setState(response.data);
    }
  };
  const getCity = async (state_id: string) => {
    const response = await getService(`city-by-state/${state_id}`);
    if (response.status === 200) {
      setCity(response.data);
    }
  };

  const handleSave = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    // fields.start_date = fields.start_date
    //   ? formatDateForAPI(fields.start_date)
    //   : '';
    // fields.end_date = formatDateForAPI(fields.end_date);
    // const data = jsonToFormData(fields);
    // if (imagePre) {
    //   const data = { ...fields, profile_image: imagePre };
    // } else {
    //   const data = { ...fields, profile_image: image };
    // }
    // fields.joining_date = formatDateForAPI(fields.joining_date);
    const data = {
      ...fields,
      profile_image: imagePre || image,
      joining_date: formatDateForAPI(fields.joining_date)
    };
    let response: any = {}; // Declare response variable with let instead of const
    if (id) {
      response = await addService(`staff/${id}`, data); // Remove const keyword
    } else {
      response = await addService('staff', data); // Remove const keyword
    }

    if (response?.status === 200) {
      setLoading(false);
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      navigate('/StaffListView');
      // if (id && !fields.actual_end_date) {
      //   navigate('/siteList');
      // } else if (id) {
      //   navigate('/completedSiteList');
      // } else {
      //   navigate('/siteList');
      // }
    } else {
      setLoading(false);
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      <h4 className="mb-4">{id ? 'Update Staff' : 'Create New Staff'}</h4>
      <Col sm={6} md={2}>
        <div className="d-flex flex-column align-items-center border rounded-circle p-2 cursor-pointer overflow-hidden mb-2">
          <label
            className={
              image
                ? 'd-none'
                : imagePre
                ? 'd-none'
                : 'text-body fs-9 cursor-pointer'
            }
            htmlFor="attachments"
          >
            <FontAwesomeIcon icon={faPaperclip} className="me-1" />
            <span className="ml-2">Upload Image</span>
          </label>

          <Form.Control
            className="d-none"
            type="file"
            id="attachments"
            onChange={handleImageChange}
          />
          {id && imagePre ? (
            <>
              <img
                src={`${imageURL}/public/${imagePre}`}
                alt="Profile Image"
                style={{ maxWidth: '100%', borderRadius: '50%' }}
              />
            </>
          ) : (
            image && (
              <img
                src={URL.createObjectURL(image)}
                alt="Profile Image"
                style={{ maxWidth: '100%', borderRadius: '50%' }}
              />
            )
          )}
        </div>
        {(imagePre || image) && (
          <Col sm={8} md={8}>
            <span
              onClick={clearImage}
              style={{
                position: 'relative',
                bottom: 0,
                left: '80px',
                cursor: 'pointer'
              }}
            >
              <p>Remove Profile </p>
            </span>
          </Col>
        )}
      </Col>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="First Name">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={fields['first_name']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Last Name">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={fields['last_name']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Email">
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={fields['email']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Mobile No">
                <Form.Control
                  type="number"
                  placeholder="Mobile No"
                  name="mobile_no"
                  value={fields['mobile_no']}
                  onChange={handleChange}
                  maxLength={10}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Aadhar Card No"
              >
                <Form.Control
                  type="number"
                  placeholder="Aadhar No"
                  name="aadhar_no"
                  value={fields['aadhar_no']}
                  onChange={handleChange}
                  maxLength={10}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTask" label="ID Card">
                <Form.Select
                  name="id_card_type"
                  value={fields['id_card_type']}
                  onChange={handleSelect}
                >
                  <option>Select ID card</option>
                  <option value={1}>Aadhar Card</option>
                  <option value={2}>PAN Card</option>
                  <option value={3}>Driving Licences</option>
                  <option value={4}>Voter ID</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                value={fields['joining_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  setFields({
                    ...fields,
                    joining_date: date
                  });
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="Project title"
                        ref={ref}
                        id="startDate"
                      />
                      <label htmlFor="startDate" className="ps-6">
                        Joining date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Monthly salary"
              >
                <Form.Control
                  type="number"
                  placeholder="Monthly salary"
                  name="monthly_salary"
                  value={fields['monthly_salary']}
                  onChange={handleChange}
                  maxLength={10}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Overtime Rate"
              >
                <Form.Control
                  type="number"
                  placeholder="Overtime Rate"
                  name="overtime_rate"
                  value={fields['overtime_rate']}
                  onChange={handleChange}
                  maxLength={10}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Working Hours"
              >
                <Form.Control
                  type="number"
                  placeholder="Working Hours"
                  name="working_hours"
                  value={fields['working_hours']}
                  onChange={handleChange}
                  maxLength={10}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Medical Leave"
              >
                <Form.Control
                  type="number"
                  placeholder="Medical Leave"
                  name="medical_leave"
                  value={fields['medical_leave']}
                  onChange={handleChange}
                  maxLength={10}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Paid Leave">
                <Form.Control
                  type="number"
                  placeholder="Paid Leave"
                  name="paid_leave"
                  value={fields['paid_leave']}
                  onChange={handleChange}
                  maxLength={10}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Bank Name">
                <Form.Control
                  type="text"
                  placeholder="Bank Name"
                  name="bank_name"
                  value={fields['bank_name']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Account No">
                <Form.Control
                  type="number"
                  placeholder="Account no"
                  name="account_no"
                  value={fields['account_no']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="IFSC Code">
                <Form.Control
                  type="text"
                  placeholder="IFSC code"
                  name="ifsc_code"
                  value={fields['ifsc_code']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Country">
                <Form.Control
                  type="text"
                  placeholder="Country"
                  name="India"
                  value={'India'}
                  // onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectPrivacy" label="State">
                <Form.Select
                  name="state"
                  value={fields['state']}
                  onChange={handleSelect}
                >
                  <option>Select State</option>
                  {state.length > 0 &&
                    state.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingSelectPrivacy"
                label="City/Village"
              >
                <Form.Select
                  name="city"
                  value={fields['city']}
                  onChange={handleSelect}
                >
                  <option>Select City</option>
                  {city.length > 0 &&
                    city.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Address">
                <Form.Control
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={fields['address']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTeam" label="Status">
                <Form.Select
                  name="status"
                  value={fields['status']}
                  onChange={handleSelect}
                >
                  <option>Select Status</option>
                  <option value={1}>Avtive</option>
                  <option value={2}>Inactive</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/staffListView">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSave}
                >
                  {id ? 'Submit' : 'Create Staff'}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateStaff;

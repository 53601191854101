/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type EditAction = () => void;
type RemoveAction = () => void;

interface ActionDropdownItemsProps {
  editAction?: EditAction; // Make the prop optional
  removeAction?: RemoveAction;
}

const QuotationDropdown: React.FC<ActionDropdownItemsProps> = ({
  editAction = () => {},
  removeAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" onClick={editAction}>
        Edit
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      {/* <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-danger"
        onClick={removeAction}
      >
        Remove
      </Dropdown.Item> */}
    </>
  );
};

export default QuotationDropdown;

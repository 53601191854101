import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
import Loader from 'helpers/Loader';

interface User {
  id: number;
  name: string;
  email: string;
  status: number;
  roll_id: number;
}

interface Fields {
  first_name: string;
  last_name: string;
  roll_id: string;
  email: string;
  password: any;
}

const UserListView = () => {
  const navigate = useNavigate();
  const [userlList, setUserlList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState<Fields>({
    first_name: '',
    last_name: '',
    roll_id: '',
    email: '',
    password: ''
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFields(prevFields => ({
      ...prevFields,
      [name]: value
    }));
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFields(prevFields => ({
      ...prevFields,
      [name]: value
    }));
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const response = await addService('create_user', fields);
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      handleClose();
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };

  const handleShow = (data: any) => {
    // setCompanyId(data.id);
    setShow(true);
    setFields({
      first_name: '',
      last_name: '',
      roll_id: '',
      email: '',
      password: ''
    });
  };

  // const confirmAlert = (id: any) => {
  //   setShowAlert(true);
  //   setContractorId(id);
  // };

  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<User>[] = [
    {
      accessorKey: 'name',
      header: 'User Name',
      meta: {
        headerProps: {
          style: { width: '45%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: ({ row: { original } }) => {
        const { email } = original;
        return (
          <Link to={`mailto:${email}`} className="fw-semibold">
            {email}
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '30%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'status',
      header: 'User Status',
      cell: ({ row: { original } }) => {
        const { status } = original;
        return <p>{status == 1 ? 'Active' : 'Inactive'}</p>;
      },
      meta: {
        headerProps: {
          style: { width: '40%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'roll_id',
      header: 'User Type',
      cell: ({ row: { original } }) => {
        const { roll_id } = original;
        return (
          <p>
            {roll_id == 1
              ? 'Admin'
              : roll_id == 2
              ? 'Inventory Manager'
              : roll_id == 3
              ? 'Staff'
              : roll_id == 4
              ? 'Contractor'
              : ''}
          </p>
        );
      },
      meta: {
        headerProps: { style: { width: '40%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
              // editAction={() => navigate(`/contractors/create-new/${id}`)}
              // removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: userlList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('user_list');
    if (response.status === 200) {
      setLoading(false);
      setUserlList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'User'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Users List</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button
                  onClick={() => setShow(true)}
                  variant="primary"
                  className="px-5"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add User
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Create New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="First Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                          value={fields['first_name']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Last Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Mobile Numbar"
                          name="last_name"
                          value={fields['last_name']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingSelectPrivacy"
                        label="User Type"
                      >
                        <Form.Select
                          name="roll_id"
                          value={fields['roll_id']}
                          onChange={handleSelect}
                        >
                          <option>Select User</option>
                          <option value="2">Inventory Manager</option>
                          <option value="3">Staff</option>
                          <option value="4">Contractor</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Email ID"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={fields['email']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Password"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Password"
                          name="password"
                          value={fields['password']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UserListView;

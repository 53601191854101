/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type EditAction = () => void;
type BonusAction = () => void;
type IncrementAction = () => void;
type RemoveAction = () => void;

interface ActionDropdownItemsProps {
  editAction?: EditAction; // Make the prop optional
  incrementAction?: IncrementAction;
  bonusAction?: BonusAction;
  removeAction?: RemoveAction;
}

const StaffActionDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  editAction = () => {},
  incrementAction = () => {},
  bonusAction = () => {},
  removeAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" onClick={editAction}>
        Edit
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-info"
        onClick={incrementAction}
      >
        Increment
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item eventKey="2" className="text-info" onClick={bonusAction}>
        Bonus
      </Dropdown.Item>{' '}
      {/* <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-danger"
        onClick={removeAction}
      >
        Remove
      </Dropdown.Item> */}
    </>
  );
};

export default StaffActionDropdownItems;

/* eslint-disable react/prop-types */
import { Dropdown } from 'react-bootstrap';

type EditAction = () => void;
type RemoveAction = () => void;
type SiteDetailsAction = () => void;
type DocumentsAction = () => void;

interface ActionDropdownItemsProps {
  editAction?: EditAction; // Make the prop optional
  removeAction?: RemoveAction;
  siteDetailsAction?: SiteDetailsAction;
  documentsAction?: DocumentsAction;
}

const WoActionDropdownItems: React.FC<ActionDropdownItemsProps> = ({
  editAction = () => {},
  // removeAction = () => {},
  siteDetailsAction = () => {},
  documentsAction = () => {}
}) => {
  return (
    <>
      <Dropdown.Item eventKey="1" onClick={editAction}>
        Edit
      </Dropdown.Item>
      {/* <Dropdown.Item eventKey="2">Export</Dropdown.Item> */}
      {/* <Dropdown.Divider />
      <Dropdown.Item
        eventKey="2"
        className="text-danger"
        onClick={removeAction}
      >
        Remove
      </Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="3"
        className="text-info"
        onClick={siteDetailsAction}
      >
        WO Details
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="4"
        className="text-info"
        onClick={documentsAction}
      >
        Documents
      </Dropdown.Item>
    </>
  );
};

export default WoActionDropdownItems;

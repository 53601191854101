import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  formatDate,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import Loader from 'helpers/Loader';

interface Industry {
  id: number;
  name: string;
  created_at: string;
}

const IndustryListView = () => {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState('');

  const [industryId, setIndustryId] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [industryList, setIndustryList] = useState([]);
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleShow = (data: any) => {
    setShow(true);
    setIndustryId(data.id);
    setIndustryName(data.name);
  };
  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setIndustryId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`industrie/${industryId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setIndustryId('');
    }
  };

  const handleClose = () => {
    setShow(false);
    setIndustryId('');
    setIndustryName('');
    setError('');
  };

  const handleSave = async () => {
    if (industryName === '') {
      setError('Please enter an industry title.');
      return;
    } else {
      const data = jsonToFormData({ name: industryName });
      let response: any = {};
      if (industryId) {
        response = await addService(`industrie/${industryId}`, data);
      } else {
        response = await addService('industrie', data);
      }
      if (response?.status === 200) {
        fetch();
        handleClose();
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
      } else if (response?.status === 500) {
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        validationErrorFromAPI(response.data);
      }
    }
  };

  const fetch = async () => {
    setLoading(true);
    const response = await getService('industrie');
    if (response.status === 200) {
      setLoading(false);
      setIndustryList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Industry>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row: { original } }) => {
        const { name } = original;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="text-body-emphasis fw-semibold">{name}</p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-items-center' }
      }
    },
    {
      accessorKey: 'created_at',
      header: 'CREATE DATE',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return <>{formatDate(created_at)}</>;
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'lastActive',
      header: 'Actions',
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-end text-body-tertiary'
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <ActionDropdownItems
                  editAction={() => handleShow(original)}
                  removeAction={() => confirmAlert(id)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: industryList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  if (loading) return <Loader />; // Show loader while loading
  // if (error) return <div>Error: {error.message}</div>; // Show error message

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Industry'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Industry</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {role === 1 && (
                  <Button
                    onClick={() => setShow(true)}
                    variant="primary"
                    className="px-5"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Industry
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                {industryId ? 'Edit' : 'Create'} Industry
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Add Industry</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter industry title"
                    autoFocus
                    value={industryName}
                    onChange={e => {
                      setIndustryName(e.target.value);
                      setError('');
                    }}
                    isInvalid={error !== ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default IndustryListView;

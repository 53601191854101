import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  Col,
  Form,
  Modal,
  Row,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { getService } from 'services/services';
import {
  dateFormat,
  openInNewTab,
  validationErrorFromAPI
} from 'helpers/utils';
import { FloatingLabel } from 'react-bootstrap';
import WoInvoiceDropdownItems from 'components/common/WoInvoiceDropdownItems';
import { postMethode } from 'apiService';
import Loader from 'helpers/Loader';

interface InvoiceList {
  id: number;
  site_ids: string;
  invoice_no: string;
  pdf_url: string;
  invoice_date: string;
  site_count: number;
  status: number;
  site_data: SiteData[];
}

interface SiteData {
  id: number;
  wo_id: number;
  site_id: string;
  site_name: string;
  budget: number;
  workorder: {
    id: number;
    wo_id: string;
    name: string;
    company: {
      id: number;
      name: string;
    };
  };
}

interface Industry {
  id: number;
  name: string;
  created_at: any;
}
interface WorkOrder {
  id: number;
  wo_id: string;
}
interface Common {
  id: number;
  name: string;
}
interface Company {
  id: number;
  name: string;
  created_at: string;
  pan_no: string;
  gst_no: string;
  address: string;
  industry: {
    name: string;
  };
}
interface Field {
  industry_id: string;
  name: string;
  country: string;
  state: string;
  city: string;
  pincode: string;
  address: string;
  pan_no: string;
  gst_no: string;
}

type EditAction = () => void;

const WorkOrderInvoice = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([]);
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [woId, setWoId] = useState();
  const [invoiceNo, setInvoiceNo] = useState();
  const [comId, setComId] = useState();
  const [role, setRole] = useState(0);
  const [woStatus, setWoStatus] = useState(0);
  const [statusFil, setStatusFil] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleShow = (data: any) => {
    setInvoiceId(data.id);
    setShow(true);
  };

  const handleSave = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('status', woStatus.toString());
    const response = await postMethode(
      `wo_invoice_update/${invoiceId}`,
      formData
    );
    if (response.status === 200) {
      setLoading(false);
      setShow(false);
      fetchInvoiceList();
    } else if (response.status === 500) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    setCompanyName('');
    setIndustryName('');
    setError('');
  };

  const fetch = async () => {
    setLoading(true);
    const response = await getService('company');
    if (response.status === 200) {
      setLoading(false);
      setCompanyList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  useEffect(() => {
    fetch();
    fetchWorkOrder();
    fetchCompanyList();
    fetchInvoiceList();
  }, []);

  const fetchInvoiceList = async () => {
    const response = await getService('workorder_invoice_filter/0/0/0/0');
    if (response.status === 200) {
      setInvoiceList(response.data);
      setInvoices(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const fetchWorkOrder = async () => {
    const response = await getService('workorder');
    if (response.status === 200) {
      setWorkOrderList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const fetchCompanyList = async () => {
    const response = await getService('company');
    if (response.status === 200) {
      setCompanyList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleStatusChange = async (id: any) => {
    if (id) {
      setStatusFil(id);
      const response = await getService(
        `workorder_invoice_filter/${invoiceNo ? invoiceNo : 0}/${
          woId ? woId : 0
        }/${comId ? comId : 0}/${id}`
      );
      if (response.status === 200) {
        setInvoiceList(response.data);
      }
    } else {
      `workorder_invoice_filter/${invoiceNo ? invoiceNo : 0}/${
        woId ? woId : 0
      }/${comId ? comId : 0}/${statusFil ? statusFil : 0}`;
    }
  };

  const handleCompanyChange = async (id: any) => {
    if (id) {
      setComId(id);
      const response = await getService(
        `workorder_invoice_filter/${invoiceNo ? invoiceNo : 0}/${
          woId ? woId : 0
        }/${id}/${statusFil ? statusFil : 0}`
      );
      if (response.status === 200) {
        setInvoiceList(response.data);
      }
    } else {
      `workorder_invoice_filter/${invoiceNo ? invoiceNo : 0}/${
        woId ? woId : 0
      }/${comId ? comId : 0}/${statusFil ? statusFil : 0}`;
    }
  };
  const handleWorkOrderChange = async (id: any) => {
    if (id) {
      setWoId(id);
      const response = await getService(
        `workorder_invoice_filter/${invoiceNo ? invoiceNo : 0}/${id}/${
          comId ? comId : 0
        }/${statusFil ? statusFil : 0}`
      );
      if (response.status === 200) {
        setInvoiceList(response.data);
      }
    } else {
      `workorder_invoice_filter/${invoiceNo ? invoiceNo : 0}/${
        woId ? woId : 0
      }/${comId ? comId : 0}/${statusFil ? statusFil : 0}`;
    }
  };
  const handleInvoiceChange = async (invoice: any) => {
    if (invoice) {
      setInvoiceNo(invoice);
      const response = await getService(
        `workorder_invoice_filter/${invoice}/${woId ? woId : 0}/${
          comId ? comId : 0
        }/${statusFil ? statusFil : 0}`
      );
      if (response.status === 200) {
        setInvoiceList(response.data);
      }
    } else {
      `workorder_invoice_filter/${invoiceNo ? invoiceNo : 0}/${
        woId ? woId : 0
      }/${comId ? comId : 0}/${statusFil ? statusFil : 0}`;
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<InvoiceList>[] = [
    {
      accessorKey: 'invoice_no',
      header: 'Invoice No.',
      cell: ({ row: { original } }) => {
        const { invoice_no, pdf_url } = original;
        return (
          <a
            onClick={() => openInNewTab(pdf_url)}
            className="text-decoration-none fw-bold fs-8"
            style={{ cursor: 'pointer' }}
          >
            {/* <p className="mb-0 ms-3 text-body-emphasis fw-semibold"> */}
            {invoice_no}
            {/* </p> */}
          </a>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'invoice_date',
      header: 'Invoice Date',
      cell: ({ row: { original } }) => {
        const { invoice_date } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {dateFormat(invoice_date)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Invoice Amount',
      cell: ({ row: { original } }) => {
        const totalBudget = original.site_data.reduce(
          (acc, site) => acc + site.budget,
          0
        );
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {totalBudget}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'workorder_id',
      header: 'WO ID',
      cell: ({ row: { original } }) => {
        const { wo_id } = original.site_data[0].workorder;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{wo_id}</p>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'site_count',
      header: 'No of Sites',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'company',
      header: 'Client Name',
      cell: ({ row: { original } }) => {
        const { name } = original.site_data[0].workorder.company;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{name}</p>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row: { original } }) => {
        const { status } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {status === 1
              ? 'Pending'
              : status === 2
              ? 'Submited'
              : status === 3
              ? 'Completed'
              : 'Cancel'}
          </p>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    // {
    //   accessorKey: 'created_at',
    //   header: 'CREATE DATE',
    //   cell: ({ row: { original } }) => {
    //     const { created_at } = original;
    //     return <>{created_at.substring(0, 10)}</>;
    //   },
    //   meta: {
    //     headerProps: { style: { width: '10%' } },
    //     cellProps: { className: 'text-body' }
    //   }
    // },

    {
      id: 'action',
      header: 'Action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <WoInvoiceDropdownItems
                  editAction={() => handleShow(original)}
                  // printAction={() => navigate('/companyBoq')}
                  // paymentStatusAction={() => handleShow(original)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: invoiceList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-5">Work Order Invoice</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="Invoice">
                  <Form.Select
                    name="Invoice"
                    onChange={e => handleInvoiceChange(e.target.value)}
                  >
                    <option value="0">Select Invoice</option>
                    {invoices.length > 0 &&
                      invoices.map((item: any) => (
                        <option key={item.id} value={item.invoice_no}>
                          {item.invoice_no}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Work Order"
                >
                  <Form.Select
                    name="Work Order"
                    onChange={e => handleWorkOrderChange(e.target.value)}
                  >
                    <option value="0">Select Work Order</option>
                    {workOrderList.length > 0 &&
                      workOrderList.map((item: WorkOrder) => (
                        <option key={item.id} value={item.id}>
                          {item.wo_id}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="Client">
                  <Form.Select
                    name="Client"
                    onChange={e => handleCompanyChange(e.target.value)}
                  >
                    <option value="0">Select Client</option>
                    {companyList.length > 0 &&
                      companyList.map((item: Company) => (
                        <option key={item.id} value={item.id}>
                          {`${item.name}-${item.industry.name}`}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="Status">
                  <Form.Select
                    name="Challan Filter"
                    onChange={e => handleStatusChange(e.target.value)}
                  >
                    <option value="0">Select Status</option>
                    <option value="2">Submited</option>
                    <option value="3">Completed</option>
                    <option value="1">Pending</option>
                    <option value="4">Cancel</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {/* <Button onClick={handleShow} variant="primary" className="px-5">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Create Invoice
                </Button> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Work Order Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <ToggleButtonGroup
                      type="radio"
                      name="options"
                      value={woStatus}
                      onChange={setWoStatus}
                    >
                      <ToggleButton id="2" variant="outline-primary" value="2">
                        Submited
                      </ToggleButton>
                      <ToggleButton id="3" variant="outline-primary" value="3">
                        Completed
                      </ToggleButton>
                      <ToggleButton id="1" variant="outline-primary" value="1">
                        Pending
                      </ToggleButton>
                      <ToggleButton id="4" variant="outline-primary" value="4">
                        Cancel
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default WorkOrderInvoice;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  dateFormat,
  formatDateForAPI,
  imageURL,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import StaffActionDropdownItems from 'components/common/StaffActionDropdownItems';
import DatePicker from 'components/base/DatePicker';
import Loader from 'helpers/Loader';

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  status: number;
  roll_id: number;
  joining_date: string;
  profile_image: any;
}

interface Fields {
  first_name: string;
  last_name: string;
  roll_id: string;
  email: string;
  password: any;
}

interface Increment {
  current_salary: string;
  total_amount: number;
  amount: string;
  increment_date: any;
  staff_id: string;
}

interface Bonus {
  current_salary: string;
  total_amount: number;
  staff_id: string;
  bonus_date: any;
  amount: string;
}

const StaffListView = () => {
  const navigate = useNavigate();
  const [staffList, setStaffList] = useState<any>([]);
  const [staffData, setStaffData] = useState<any>([]);
  const [contractorId, setContractorId] = useState('');
  const [staffId, setStaffId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [show, setShow] = useState(false);
  const [showIncrement, setShowIncrement] = useState(false);
  const [showBonus, setShowBonus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState<Fields>({
    first_name: '',
    last_name: '',
    roll_id: '',
    email: '',
    password: ''
  });

  const initialFieldsInc = {
    current_salary: '',
    total_amount: 0,
    amount: '',
    increment_date: '',
    staff_id: ''
  };

  const initialFieldsBonus = {
    current_salary: '',
    total_amount: 0,
    staff_id: '',
    bonus_date: '',
    amount: ''
  };

  const [fieldsInc, setFieldsInc] = useState<Increment>({
    current_salary: '',
    total_amount: 0,
    amount: '',
    increment_date: '',
    staff_id: ''
  });

  const [fieldsBonus, setFieldsBonus] = useState<Bonus>({
    current_salary: '',
    total_amount: 0,
    staff_id: '',
    bonus_date: '',
    amount: ''
  });

  console.log('fieldsInc', fieldsInc);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('staff');
    if (response.status === 200) {
      setLoading(false);
      setStaffList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };

  const handleChangeIncrement = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const t_amount = Number(fieldsInc.current_salary) + Number(value);
    setFieldsInc(prevFields => ({
      ...prevFields,
      total_amount: t_amount,
      staff_id: staffId
    }));
    setFieldsInc(prevFields => ({
      ...prevFields,
      [name]: value
    }));
  };

  const handleChangeBonus = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const t_amount = Number(fieldsBonus.current_salary) + Number(value);
    setFieldsBonus(prevFields => ({
      ...prevFields,
      total_amount: t_amount,
      staff_id: staffId
    }));

    console.log('t_amount');
    setFieldsBonus(prevFields => ({
      ...prevFields,
      [name]: value
    }));
  };

  // const handleIncrement = () => {
  //   alert('Increment Function Call');
  // };

  const handleIncrement = async () => {
    const response = await addService('add-staff-increment', fieldsInc);
    if (response.status === 200) {
      incrementClose();
      toast.success('Increment Added!');
    } else {
      toast.error('Faild');
    }
  };

  const handleBonus = async () => {
    const response = await addService('add-staff-bonus', fieldsBonus);
    if (response.status == 200) {
      bonusClose();
      toast.success('Bonus Added!');
    } else {
      console.log('ERROR');
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFields(prevFields => ({
      ...prevFields,
      [name]: value
    }));
  };

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFields(prevFields => ({
      ...prevFields,
      [name]: value
    }));
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const response = await addService('create_user', fields);
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      handleClose();
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };

  const handleShow = (data: any) => {
    setShow(true);
    setFields({
      first_name: '',
      last_name: '',
      roll_id: '',
      email: '',
      password: ''
    });
  };

  const increment = (data: any) => {
    setStaffId(data.id);
    setShowIncrement(true);
    setStaffData(data);
    setFieldsInc(prevFields => ({
      ...prevFields,
      current_salary: data.monthly_salary
    }));
  };

  const bonus = (data: any) => {
    setStaffId(data.id);
    setShowBonus(true);
    setStaffData(data);
    setFieldsBonus(prevFields => ({
      ...prevFields,
      current_salary: data.monthly_salary
    }));
  };

  const incrementClose = () => {
    setShowIncrement(false);
    setStaffId('');
    setFieldsInc(initialFieldsInc);
  };

  const bonusClose = () => {
    setShowBonus(false);
    setStaffId('');
    setFieldsBonus(initialFieldsBonus);
  };

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };

  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<User>[] = [
    {
      accessorKey: 'full_name',
      header: 'Staff Name',
      cell: ({ row: { original } }) => {
        const { first_name, last_name, profile_image } = original;
        return (
          // <Link
          //   to={`/contractorProfile/${id}`}
          //   className="d-flex align-items-center text-body"
          // >
          <div className="d-flex align-items-center text-body">
            <Avatar src={`${imageURL}/public/${profile_image}`} size="m" />
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {first_name} {last_name}
            </p>
          </div>
          // </Link>
        );
      },
      meta: {
        headerProps: {
          // style: { width: '15%', minWidth: '200px' },
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: ({ row: { original } }) => {
        const { email } = original;
        return (
          <Link to={`mailto:${email}`} className="fw-semibold">
            {email}
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '12%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'joining_date',
      header: 'Joining Date',
      cell: ({ row: { original } }) => {
        const { joining_date } = original;
        return <>{dateFormat(joining_date)}</>;
      },
      meta: {
        headerProps: {
          style: { width: '12%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'paid_leave',
      header: 'Paid Leave',
      meta: {
        headerProps: {
          style: { width: '12%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'medical_leave',
      header: 'Medical Leave',
      meta: {
        headerProps: {
          style: { width: '12%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row: { original } }) => {
        const { status } = original;
        return <>{status == 1 ? 'Active' : 'Inactive'}</>;
      },
      meta: {
        headerProps: {
          style: { width: '12%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'overtime_rate',
      header: 'Over Time Rate',
      meta: {
        headerProps: {
          style: { width: '12%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'monthly_salary',
      header: 'Salary',
      meta: {
        headerProps: {
          style: { width: '12%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'monthly_salary',
      header: 'Credited Salary',
      meta: {
        headerProps: {
          style: { width: '12%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'address',
      header: 'Address',
      meta: {
        headerProps: {
          style: { width: '12%', minWidth: '100px' },
          className: 'pe-3'
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <StaffActionDropdownItems
                editAction={() => navigate(`/createStaff/${id}`)}
                incrementAction={() => increment(original)}
                bonusAction={() => bonus(original)}
                // removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: staffList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'User'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Staff List</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button
                  //   onClick={() => setShow(true)}
                  onClick={() => navigate('/CreateStaff')}
                  variant="primary"
                  className="px-5"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Staff
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Create New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="First Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                          value={fields['first_name']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Last Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Mobile Numbar"
                          name="last_name"
                          value={fields['last_name']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={4}>
                      <FloatingLabel
                        controlId="floatingSelectPrivacy"
                        label="User Type"
                      >
                        <Form.Select
                          name="roll_id"
                          value={fields['roll_id']}
                          onChange={handleSelect}
                        >
                          <option>Select User</option>
                          <option value="2">Inventory Manager</option>
                          <option value="3">Staff</option>
                          <option value="4">Contractor</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Email ID"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={fields['email']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Password"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Password"
                          name="password"
                          value={fields['password']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showIncrement} onHide={incrementClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <p className="text-uppercase">Salary Increment</p>
                <div className="d-flex justify-content-between">
                  <h6>
                    Name: {`${staffData.first_name} ${staffData.last_name}`}
                  </h6>
                  <h6 className="px-5">DOJ: {staffData.joining_date}</h6>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Current Salary"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Current Salary"
                          name="current_salary"
                          value={staffData.monthly_salary}
                          onChange={handleChangeIncrement}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <DatePicker
                        value={fieldsInc['increment_date']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFieldsInc({
                            ...fieldsInc,
                            increment_date: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                type="text"
                                placeholder="Project title"
                                ref={ref}
                                id="startDate"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                Bonus Date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Amount"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          name="amount"
                          value={fieldsInc['amount']}
                          onChange={handleChangeIncrement}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Total amount"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Total Amount"
                          name="total_amount"
                          value={fieldsInc.total_amount}
                          onChange={handleChangeIncrement}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={incrementClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleIncrement}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showBonus} onHide={bonusClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <p className="text-uppercase">Bonus</p>
                <div className="d-flex justify-content-between">
                  <h6>
                    Name: {`${staffData.first_name} ${staffData.last_name}`}
                  </h6>
                  <h6 className="px-5">DOJ: {staffData.joining_date}</h6>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Current Salary"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Current Salary"
                          name="first_name"
                          // value={fields['first_name']}
                          value={staffData.monthly_salary}
                          onChange={handleChangeBonus}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <DatePicker
                        value={fieldsBonus['bonus_date']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFieldsBonus({
                            ...fieldsBonus,
                            bonus_date: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                type="text"
                                placeholder="Project title"
                                ref={ref}
                                id="startDate"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                Bonus Date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Bonus Amount"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Amount"
                          name="amount"
                          value={fieldsBonus['amount']}
                          onChange={handleChangeBonus}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Total Amount"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Total Amount"
                          name="total_amount"
                          value={fieldsBonus.total_amount}
                          onChange={handleChangeBonus}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={bonusClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleBonus}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default StaffListView;

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import WoActionDropdownItems from 'components/common/WoActionDropdownItems';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import Loader from 'helpers/Loader';
import {
  dateFormat,
  formatNumber,
  validationErrorFromAPI
} from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteService, getService } from 'services/services';

interface Project {
  id: number;
  name: string;
  wo_id: string;
  end_date: string;
  start_date: string;
  wo_amount: number;
  no_of_sites: string;
  complete_site: string;
  site: Site[];
  company: {
    name: string;
  };
  industry: {
    name: string;
  };
}

interface Site {
  id: number;
}

const WorkOrderListView = () => {
  const navigate = useNavigate();
  const [workOrderList, setWorkOrderList] = useState([]);
  const [workOrderId, setWorkOrderId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setWorkOrderId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`workorder/${workOrderId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setWorkOrderId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const workOrderListTableColumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'name',
      header: 'WO Number',
      cell: ({ row: { original } }) => {
        const { name, id } = original;
        return (
          <a
            // onClick={() => navigate('/workOrderDetails')}
            onClick={() => navigate(`/workOrderDetails/${id}`)}
            className="text-decoration-none fw-bold fs-8"
            style={{ cursor: 'pointer' }}
          >
            {name}
          </a>
        );
      },
      meta: {
        cellProps: { className: 'white-space-nowrap py-4' },
        headerProps: { style: { width: '30%' } }
      }
    },
    {
      accessorKey: 'wo_id',
      header: 'WO ID',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'company.name',
      header: 'Client Name',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'industry.name',
      header: 'Industry Name',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },

    {
      header: 'WO date',
      accessorKey: 'start_date',
      cell: ({ row: { original } }) => {
        const { start_date } = original;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {dateFormat(start_date)}
          </span>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-3' }
      }
    },
    {
      header: 'Estimate end date',
      accessorKey: 'end_date',
      cell: ({ row: { original } }) => {
        const { end_date } = original;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {dateFormat(end_date)}
          </span>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
        headerProps: { style: { width: '15%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'wo_amount',
      header: 'WO Value',
      cell: ({ row: { original } }) => {
        const { wo_amount } = original;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {formatNumber(wo_amount)}
          </span>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'task',
      header: 'Document View',
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '12%' }, className: 'ps-3' }
      }
    },
    {
      id: 'status',
      header: 'Progress',
      cell: ({ row: { original } }) => {
        const { complete_site, site } = original;

        const no_Of_Sites = site.length;

        return (
          <>
            <p className="text-body-secondary fs-10 mb-0">
              {complete_site} / {no_Of_Sites}
            </p>
            <ProgressBar
              now={(parseInt(complete_site) / no_Of_Sites) * 100}
              // now={(50 / 100) * 100}
              style={{ height: 3 }}
              variant="success"
            />
          </>
        );
      },
      meta: {
        cellProps: { className: 'ps-8 py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-8' }
      }
    },
    {
      id: 'status',
      header: 'Status',
      meta: {
        cellProps: { className: 'ps-8 py-4' },
        headerProps: { style: { width: '10%' }, className: 'ps-8' }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <WoActionDropdownItems
                  editAction={() => navigate(`/workOrder/create-new/${id}`)}
                  removeAction={() => confirmAlert(id)}
                  siteDetailsAction={() => navigate(`/workOrderDetails/${id}`)}
                  documentsAction={() => navigate(`/workOrderDocuments/${id}`)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: workOrderList,
    columns: workOrderListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('workorder');
    if (response.status === 200) {
      setLoading(false);
      setWorkOrderList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Work Order'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Work Order</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({workOrderList.length})
            </span>
          </h2>
          {role === 1 && (
            <Link className="btn btn-primary px-5" to="/workOrder/create-new">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Create Work Order
            </Link>
          )}
        </div>
        <ProjectsTopSection activeView="list" />
        <SupplireListTable />
      </AdvanceTableProvider>
    </div>
  );
};

export const SupplireListTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default WorkOrderListView;

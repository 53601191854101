import { ChangeEvent, useEffect, useState } from 'react';
import {
  dateFormat,
  openInNewTab,
  validationErrorFromAPI
} from 'helpers/utils';
import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import { addService, deleteService, getService } from 'services/services';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import useAdvanceTable from 'hooks/useAdvanceTable';
// import { Project } from 'data/project-management/projects';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { baseLocalURL } from 'config';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import Loader from 'helpers/Loader';

interface Project {
  id: number;
  staff: {
    first_name: string;
    last_name: string;
    email: string;
    mobile_no: string;
    aadhar_no: string;
    country: number;
    state: number;
    city: number;
    address: string;
    bank_name: string;
    ifsc_code: string;
    account_no: string;
    id_card_type: number;
    status: number;
    joining_date: string;
    paid_leave: number;
    medical_leave: number;
    working_hours: number;
    overtime_rate: string;
    w_per_hour: string;
    monthly_salary: number;
    profile_image: any;
  };
}

interface Props {
  siteId: any;
}
// eslint-disable-next-line react/prop-types
const AddStaff: React.FC<Props> = ({ siteId }) => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [staffList, setStaffList] = useState<any>(null);
  const [expensesList, setExpensesList] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [staffId, setStaffId] = useState('');
  const [loading, setLoading] = useState(true);

  const paymentModes = {
    '1': 'Online',
    '2': 'Cash',
    '3': 'Cheque',
    '4': 'DD'
  };

  const fetch = async () => {
    setLoading(true);
    const response = await getService('staff');
    if (response.status === 200) {
      setLoading(false);
      const transformedArray: [] = response.data.map((item: any) => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`
      }));
      setStaffList(transformedArray);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };

  const getStaff = async () => {
    const response = await getService(`staffmape_by_id/${siteId}`);
    if (response.status === 200) {
      setStaffData(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  useEffect(() => {
    fetch();
    getStaff();
  }, []);

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setStaffId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`staffmape/${staffId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      getStaff();
      setShowAlert(false);
      //   setSiteStageId('');
    } else {
      toast.error('failed!');
      setShowAlert(false);
    }
  };

  const handleChange = (newValue: MultiValue<any>) => {
    setSelectedOption(newValue as any);
  };

  const handleSave = async () => {
    if (selectedOption == null) {
      toast.error('No options selected');
      return;
    }

    // Ensure selectedOption is an array
    const optionsArray = Array.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];

    if (optionsArray.length == 0) {
      toast.error('No options selected');
      return;
    }

    // Convert selectedOption array to comma-separated string
    const selectedValues = optionsArray.map(option => option.value).join(',');
    // console.log('Selected values:', selectedValues);

    const formData = new FormData();
    formData.append('site_id', siteId);
    formData.append('staff_id', selectedValues);

    let response: any = {};
    // if (id) {
    //   response = await addService(`site_stage_m/${id}`, formData);
    // } else {
    response = await addService(`staffmape`, formData);
    // }
    if (response?.status === 200) {
      getStaff();
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      // navigate('/material');
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Project>[] = [
    {
      accessorKey: 'Name',
      header: 'Staff Name',
      cell: ({ row: { original } }) => {
        const { first_name, last_name } = original.staff;
        return (
          <Link to="#!" className=" d-flex align-items-center text-body">
            <p className="mb-0  text-body-emphasis fw-semibold">
              {`${first_name} ${last_name}`}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'mobile_no',
      header: 'Mobile No',
      cell: ({ row: { original } }) => {
        const { mobile_no } = original.staff;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {mobile_no ?? ''}
          </span>
        );
      },
      meta: {
        headerProps: { style: { width: '15%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'Email',
      header: 'Email',
      cell: ({ row: { original } }) => {
        const { email } = original.staff;
        return (
          <span className="text-body-secondary fs-9 mb-0">{email ?? ''}</span>
        );
      },
      meta: {
        headerProps: { style: { width: '15%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'aadhar_no',
      header: 'Aadhar No',
      cell: ({ row: { original } }) => {
        const { aadhar_no } = original.staff;
        return (
          <span className="text-body-secondary fs-9 mb-0">
            {aadhar_no ?? ''}
          </span>
        );
      },
      meta: {
        cellProps: { className: 'ps-3 text-body py-4' },
        headerProps: { style: { width: '15%' }, className: 'ps-3' }
      }
    },
    {
      accessorKey: 'working_hours',
      header: 'Working hours',
      cell: ({ row: { original } }) => {
        const { working_hours } = original.staff;
        return (
          <span className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {working_hours ?? ''}
          </span>
        );
      },
      meta: {
        cellProps: { className: 'text-center' },
        headerProps: { style: { width: '15%' }, className: 'text-center' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row: { original } }) => {
        const { status } = original.staff;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {status === 0
              ? 'Pending'
              : status === 1
              ? 'Active'
              : status === 2
              ? 'Inactive'
              : 'Unknown Status'}
          </p>
        );
      },
      meta: {
        cellProps: { className: 'text-center' },
        headerProps: { style: { width: '15%' }, className: 'text-center' }
      }
    },
    // {
    //   accessorKey: 'bill_doc',
    //   header: 'Document',
    //   cell: ({ row: { original } }) => {
    //     const { bill_doc } = original.staff;
    //     return (
    //       <Link
    //         to="#"
    //         onClick={() => openInNewTab(`${baseLocalURL}/public/${bill_doc}`)}
    //       >
    //         {bill_doc && (
    //           <img
    //             src={`${baseLocalURL}/public/${bill_doc}`}
    //             width="40"
    //             height="40"
    //             style={{ borderRadius: 20 }}
    //             alt="Document"
    //           />
    //         )}
    //       </Link>
    //     );
    //   },
    //   meta: {
    //     cellProps: { className: 'ps-3 text-body py-4' },
    //     headerProps: { style: { width: '12%' }, className: 'ps-3' }
    //   }
    // },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <RevealDropdownTrigger>
            <RevealDropdown>
              <ActionDropdownItems
                // editAction={() => handleShow(original)}
                removeAction={() => confirmAlert(id)}
              />
            </RevealDropdown>
          </RevealDropdownTrigger>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: staffData,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });
  if (loading) return <Loader />;

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Staff Record'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <Row>
        <Col>
          <Select
            isMulti // Use isMulti instead of multiple
            value={selectedOption}
            onChange={handleChange}
            options={staffList}
            placeholder="Select Staff..."
          />
        </Col>
        <Col>
          <Button onClick={handleSave}>Add Staff</Button>
        </Col>
      </Row>
      <div className="mb-9">
        {/* <h4 className="mb-5">Site Expense</h4> */}

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3 mt-1">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search..."
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>

              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {/* <Button onClick={handleShow} variant="primary" className="px-5">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Site Expense
                </Button> */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            {staffData.length === 0 ? (
              <h4 className="text-center py-2">Data not found</h4>
            ) : (
              <MembersTable />
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};
export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};
export default AddStaff;

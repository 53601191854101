import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  formatDateForAPI,
  imageURL,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import DatePicker from 'components/base/DatePicker';
import Loader from 'helpers/Loader';

interface Contractor {
  id: number;
  amount: number;
  staff: {
    first_name: string;
    last_name: string;
    profile_image: any;
    email: string;
    mobile_no: number;
    site_count: number;
    address: string;
    joining_date: string;
  };
}

interface StaffCollection {
  staff: Staff;
  amount: string;
  bonus_date: string;
}

interface Staff {
  id: number;
  joining_date: string;
  last_name: string;
  first_name: string;
  monthly_salary: any;
}

interface Bonus {
  current_salary: string;
  total_amount: number;
  amount: string;
  bonus_date: any;
  staff_id: string;
}

const BonusListView = () => {
  const navigate = useNavigate();
  const [contractorlList, setContractorlList] = useState([]);
  const [contractorId, setContractorId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showBonus, setShowBonus] = useState(false);
  const [role, setRole] = useState(0);
  const [staffId, setStaffId] = useState('');
  const [loading, setLoading] = useState(true);
  const [staffData, setStaffData] = useState<StaffCollection | undefined>(
    undefined
  );

  const [fieldsBonus, setFieldsBonus] = useState<Bonus>({
    current_salary: '',
    total_amount: 0,
    amount: '',
    bonus_date: '',
    staff_id: ''
  });

  const handleChangeIncrement = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldsBonus(prevFields => ({
      ...prevFields,
      total_amount:
        Number(staffData?.staff?.monthly_salary ?? 0) + Number(value),
      staff_id: staffId
    }));
    setFieldsBonus(prevFields => ({
      ...prevFields,
      [name]: value
    }));
  };

  const bonus = (data: any) => {
    setStaffId(data.id);
    setShowBonus(true);
    setStaffData(data);
    setFieldsBonus(prevFields => ({
      ...prevFields,
      current_salary: data.monthly_salary,
      amount: data.amount,
      bonus_date: data.bonus_date,
      total_amount:
        Number(data?.amount ?? 0) + Number(data?.staff?.monthly_salary ?? 0)
    }));
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const incrementClose = () => {
    setShowBonus(false);
  };

  const handleBonus = async () => {
    setLoading(true);
    const response = await addService(
      `update-staff-bonus/${staffId}`,
      fieldsBonus
    );
    if (response.status === 200) {
      setLoading(false);
      fetch();
      incrementClose();
      toast.success('Bonus Added');
    } else {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setContractorId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`contractor/${contractorId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setContractorId('');
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Contractor>[] = [
    {
      accessorKey: 'full_name',
      header: 'Staff Name',
      cell: ({ row: { original } }) => {
        const { first_name, last_name, profile_image } = original.staff;
        return (
          <Link
            // to={`/contractorProfile/${id}`}
            to={`/#`}
            className="d-flex align-items-center text-body"
          >
            <Avatar src={`${imageURL}/public/${profile_image}`} size="m" />
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {first_name} {last_name}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          // style: { width: '15%', minWidth: '200px' },
          style: { width: '25%', minWidth: '200px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: ({ row: { original } }) => {
        const { email } = original.staff;
        return (
          <Link to={`mailto:${email}`} className="fw-semibold">
            {email}
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'mobile_no',
      header: 'Bonus Amount',
      cell: ({ row: { original } }) => {
        const { amount } = original;
        return (
          <Link to={`/#`} className="fw-bold text-body-emphasis">
            {amount}
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'bonus_date',
      header: 'bonus date',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'staff.joining_date',
      header: 'Joining Date',
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-end text-body-tertiary'
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1) {
          return (
            <RevealDropdownTrigger>
              <RevealDropdown>
                <ActionDropdownItems
                  editAction={() => bonus(original)}
                  //   editAction={() => navigate(`/managers/create-new/${id}`)}
                  // removeAction={() => confirmAlert(id)}
                />
              </RevealDropdown>
            </RevealDropdownTrigger>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: contractorlList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('show-all-staff-bonus');
    if (response.status === 200) {
      setLoading(false);
      // setContractorlList(response.data);
      const contractorsWithFullName = response.data.map(
        (contractor: Contractor) => ({
          ...contractor,
          full_name: `${contractor.staff.first_name} ${contractor.staff.last_name}`
        })
      );
      setContractorlList(contractorsWithFullName);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Contractor'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h2 className="mb-5">Bonus List</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {/* {role === 1 && (
                  <Link
                    className="btn btn-primary px-5"
                    to="/managers/create-new"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Manager
                  </Link>
                )} */}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={showBonus} onHide={incrementClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <p className="text-uppercase">Bonus</p>
                <div className="d-flex justify-content-between">
                  <h6>{`${staffData?.staff.first_name || 'N/A'} ${
                    staffData?.staff.last_name || 'N/A'
                  }`}</h6>
                  <h6 className="px-5">
                    DOJ: {staffData?.staff.joining_date || 'N/A'}
                  </h6>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Current Salary"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Current Salary"
                          name="current_salary"
                          value={staffData?.staff.monthly_salary}
                          onChange={handleChangeIncrement}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <DatePicker
                        value={fieldsBonus['bonus_date']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFieldsBonus({
                            ...fieldsBonus,
                            bonus_date: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                type="text"
                                placeholder="Project title"
                                ref={ref}
                                id="startDate"
                              />
                              <label htmlFor="startDate" className="ps-6">
                                Bonus Date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Amount"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          name="amount"
                          value={fieldsBonus['amount']}
                          onChange={handleChangeIncrement}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Total amount"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Total Amount"
                          name="total_amount"
                          value={fieldsBonus.total_amount}
                          onChange={handleChangeIncrement}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={incrementClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleBonus}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default BonusListView;
